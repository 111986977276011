import React from 'react';
import s from './Footer.module.css';
import classNames from 'classnames';
import { CiFacebook, CiTwitter, CiLinkedin } from 'react-icons/ci';

const Footer = () => {
  return (
    <footer className={s.footer}>
      <div className={classNames(s.container, s.footer_content)}>
        <div className={s.footer_section}>
          <h3>About Us</h3>
          <p>
            SUPPLYCHAINS LIMITED s.r.o. specializes in providing services for agriculture, horticulture, fish farming, forestry, and hunting, as well as professional forest management and preparation of forest economic reports.
          </p>
          <br />
          <p>Reg number: 220 89 161</p>
        </div>
        <div className={s.footer_section}>
          <h3>Contact</h3>
          <p>Address: Hybešova 985/30, Staré Brno, 602 00 Brno, Czech Republic</p>
          <p>Email: supplychain755@gmail.com</p>
        </div>
        <div className={s.footer_section}>
          <h3>Follow Us</h3>
          <div className={s.social_icons}>
            <a href='https://facebook.com' target='_blank' rel='noopener noreferrer'>
              <CiFacebook />
            </a>
            <a href='https://twitter.com' target='_blank' rel='noopener noreferrer'>
              <CiTwitter />
            </a>
            <a href='https://linkedin.com' target='_blank' rel='noopener noreferrer'>
              <CiLinkedin />
            </a>
          </div>
        </div>
      </div>
      <div className={s.footer_bottom}>
      <p>&copy; {new Date().getFullYear()} SUPPLYCHAINS LIMITED s.r.o. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
