import React from 'react';
import s from './Services.module.css';
import { FaSeedling, FaFish, FaTree } from 'react-icons/fa';
import { GiGreenhouse } from 'react-icons/gi';
import classNames from 'classnames';

const Services = () => {
  return (
    <section className={classNames(s.services, s.container)} id='services'>
      <h2>Our Services</h2>
      <div className={s.service_cards}>
        <div className={s.service_card}>
          <FaSeedling className={s.icon} />
          <h3>Agricultural Solutions</h3>
          <ul>
            <li>High-quality seeds, fertilizers, and innovative equipment for improved yields.</li>
            <li>Precision farming techniques to optimize crop management and increase productivity.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <GiGreenhouse className={s.icon} />
          <h3>Horticulture Services</h3>
          <ul>
            <li>Comprehensive plant care products and expert consulting for healthy growth.</li>
            <li>Automation solutions for greenhouses and nurseries to maximize efficiency.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaFish className={s.icon} />
          <h3>Aquaculture & Fish Farming</h3>
          <ul>
            <li>Quality fish feed and water quality systems to support sustainable farming.</li>
            <li>Consulting on fish health and habitat to optimize fish production.</li>
          </ul>
        </div>
        <div className={s.service_card}>
          <FaTree className={s.icon} />
          <h3>Forestry Support</h3>
          <ul>
            <li>Reforestation and sustainable forestry solutions to maintain ecological balance.</li>
            <li>Timber management and conservation practices for long-term forest health.</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Services;
