import React from 'react';
import { LuMail } from 'react-icons/lu';
import { SlLocationPin } from 'react-icons/sl';
import s from './ContactUs.module.css'; 
import classNames from 'classnames';

const ContactUs = () => {
  return (
    <section className={classNames(s.contact_us, s.container)} id='contact'>
      <div className={s.left}>
        <h2>Contact Us</h2>
        <p>You can contact us at the email below, and we'll get back to you as soon as possible.</p>
        <div className={s.block}>
          <LuMail className={s.icon} />
          <a className={s.mail} href='mailto:supplychain755@gmail.com'>
            supplychain755@gmail.com
          </a>
        </div>
        <div className={s.block}>
          <SlLocationPin className={s.icon} />
          <p className={s.address}>
            Hybešova 985/30, Staré Brno, 602 00 Brno, Czech Republic
          </p>
        </div>
        <div className={s.block}>
          <p className={s.director}>
            Director: Milana Lakatosh
          </p>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
