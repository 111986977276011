import React from 'react';
import s from './Hero.module.css';
import hero1 from '../../../../assets/hero.jpg';
import { FaQuoteLeft } from 'react-icons/fa';
import classNames from 'classnames';

const Hero = () => {
  return (
    <section className={classNames(s.hero, s.container)} id='home'>
      <div className={s.hero_content}>
        <h1>SUPPLYCHAINS LIMITED s.r.o.</h1>
        <p>
          At SUPPLYCHAINS LIMITED, we are dedicated to supporting sustainable growth in agriculture, horticulture, fish farming, and forestry industries. Our team combines decades of experience with the latest technology to offer tailored trade and support services, enabling clients to optimize yields, improve resource efficiency, and promote ecological balance. Operating globally, we are committed to advancing food security, ecosystem health, and responsible production methods.
        </p>
        <a href='#services' className={s.get_started_button}>Explore Our Services</a>
      </div>
      <div className={s.hero_image}>
        <img src={hero1} alt='Sustainable Solutions' />
        <div className={s.hero_image_text}>
          <FaQuoteLeft className={s.icon} />
          <p>
            Advancing food security, ecosystem health, and responsible production methods across the globe.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
