import React from 'react';
import s from './WhyChooseUs.module.css';
import classNames from 'classnames';
import { FaAward, FaChartLine, FaHandshake, FaTools } from 'react-icons/fa';

const WhyChooseUs = () => {
    return (
      <section className={classNames(s.why_choose_us, s.container)} id='about'>
        <h2>Why Choose Us?</h2>
        <div className={s.why_choose_us_cards}>
          <div className={s.why_choose_us_card}>
            <FaAward className={s.why_choose_us_icon} />
            <h3>Experienced Team</h3>
            <p>Our team of consultants has over 10 years of experience, with expertise ranging from farming, forestry, and fisheries to the environment, natural capital, and the rural economy.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaTools className={s.why_choose_us_icon} />
            <h3>Innovative Solutions</h3>
            <p>We harness technology and best practices tailored to each industry, offering cutting-edge solutions to our clients.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaChartLine className={s.why_choose_us_icon} />
            <h3>Sustainability Focused</h3>
            <p>Our methods promote ecological responsibility, aiming for a low environmental impact while supporting long-term sustainability.</p>
          </div>
          <div className={s.why_choose_us_card}>
            <FaHandshake className={s.why_choose_us_icon} />
            <h3>Global Reach, Local Insights</h3>
            <p>With a global presence, we understand regional nuances and deliver customized solutions to meet the unique needs of each market.</p>
          </div>
        </div>
      </section>
    );
};

export default WhyChooseUs;
