import React from 'react';
import s from './Testimonials.module.css';
import avt01 from '../../../../assets/avt01.png';
import avt02 from '../../../../assets/avt02.png';
import { FaStar } from 'react-icons/fa';

const testimonialsData = [
  {
    name: "Global AgroFarm",
    title: "Client, Kenya",
    img: avt02,
    comment: "SUPPLYCHAINS LIMITED helped us implement precision farming, and our yield increased by 30%. Their support and expertise made a real difference."
  },
  {
    name: "BlueWave Aquaculture",
    title: "Client, Norway",
    img: avt01,
    comment: "Their fish farming solutions were game-changers. They provided quality feed and systems that optimized our production sustainably."
  }
];

const Testimonials = () => {
  return (
    <div className={s.testimonials} id="testimonials">
      <h2 className={s.main_title}>Testimonials</h2>
      <div className={s.container}>
        {testimonialsData.map(item => (
          <div className={s.box} key={item.name}>
            <div className={s.image}>
              <img src={item.img} alt="avatar" />
            </div>
            <h3>{item.name}</h3>
            <span className={s.title}>{item.title}</span>
            <div className={s.rate}>
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
              <FaStar className={s.filled} />
            </div>
            <p>{item.comment}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
